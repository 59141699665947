import React from "react";
import TableOrder from "./components/order-table/TableOrder";

const Order = () => {
  return (
    <div>
      <TableOrder />
    </div>
  );
};

export default Order;
