import React from "react";
import { Link } from "react-router-dom";

const TableHeader = ({
  searchQuery,
  tableName,
  tableSubtitle,
  buttonLink,
  buttonName,
  placeHolder,
}) => {
  return (
    <div className="grid grid-cols-3 items-center">
      <div>
        <input
          type="text"
          className="border px-4 py-2 rounded-2xl w-[350px] focus:outline-none"
          placeholder={placeHolder}
          onChange={searchQuery}
        />
      </div>
      <div className="text-center">
        <h1 className="text-2xl font-bold">{tableName}</h1>
        <p className="text-sm">{tableSubtitle}</p>
      </div>
      <div className="flex justify-end">
        <div>
          <Link
            to={buttonLink}
            className="text-white text-sm bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] px-4 py-2 rounded-2xl"
          >
            {buttonName}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
